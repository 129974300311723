<template>
  <div class="container">
    <div class="attendance-report mt-4 mb-4">
      <h4 class="text-center">Посещаемость на {{getTodayDate}}</h4>

      <div class="col-md-12 mt-4 mb-4">
        <div class="card">

          <div class="card-body">
            <div class="card-title mb-4">
              <div class="row">
                <div class="col-12 ml-3">
                  <h2 class="d-block"
                      style="font-size: 1.3rem; font-weight: bold;">Информация о курсе</h2>
                </div>
              </div>
            </div>

            <div>


              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Название курса:</strong></p></div>
                <div class="col-md-9">
                  {{ attendanceReport_form.courseInfos?.course_name }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Язык обучения:</strong></p></div>
                <div class="col-md-9">
                  {{ attendanceReport_form.courseInfos?.formationEducationProgram?.educationDiscipline?.language?.native_name }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Курс:</strong></p></div>
                <div class="col-md-9">
                  {{ attendanceReport_form.courseInfos?.educationCurriculum?.study_course }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Семестр:</strong></p></div>
                <div class="col-md-9">
                  {{ attendanceReport_form.courseInfos?.formationEducationProgram?.semester }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Кредит:</strong></p></div>
                <div class="col-md-9">
                  {{ attendanceReport_form.courseInfos?.formationEducationProgram?.credit }}
                </div>
              </div>




                <div class="row mt-4">
                  <div class="col-md-3"><p><strong>Группы</strong></p></div>
                  <div class="col-md-9">
                    <select class="form-control form-select"
                            @input="changeGroup($event)">
                      <option
                          v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...attendanceReport_form.studentGroups]"
                          :value="item.id"
                          :key="index">{{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

              <!-- Группы для языкового центра-->
<!--              <div v-else>-->
<!--                <div class="row mt-4">-->
<!--                  <div class="col-md-3"><p><strong>Группы</strong></p></div>-->
<!--                  <div class="col-md-9">-->
<!--                    <select class="form-control form-select"-->
<!--                            @input="changeGroupRating($event)">-->
<!--                      <option-->
<!--                          v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...attendanceReport_form.studentGroupsRatings]"-->
<!--                          :value="item.id"-->
<!--                          :key="index">{{ item.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->




            </div>


          </div>


        </div>

        <div class="attendance mt-4" v-if="groupId != 0">



<!--          <div class="calendar">-->
<!--            <Calendar v-model="date1" :inline="true" :minDate="minDate" :maxDate="maxDate" :manualInput="false" @date-select="changeAttendanceReportDay(date1)" :disabledDays="[0,6]"/>-->
<!--          </div>-->


          <div>
            <DataTable :value="filteredStudents"
                       stripedRows responsiveLayout="scroll">

              <Column field="barcode" header="Баркод"></Column>
              <Column header="ФИО">
                <template #body="{data}">
                  {{data.last_name}} {{data.first_name}}
                </template>
              </Column>

              <Column header="Посещение">
                <template #body="{data}">

                  <div v-if="attendanceReport_form?.attendanceReportData?.find(i => i.student_id == data.id)">
                    <select class="form-control form-select">
                      <option
                          v-for="(item, index) in attendanceReport_form.attendanceType"
                          :value="item.id"
                          :selected="item.id == attendanceReport_form?.attendanceReportData?.find(i => i.student_id == data.id)?.attendance_type_id"
                          :key="index" disabled>{{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <select class="form-control form-select"
                            @input="changeAttendance(data.id, $event)">
                      <option
                          v-for="(item, index) in attendanceReport_form.attendanceType"
                          :value="item.id"
                          :selected="item.id == attendanceReport_form?.attendanceReportData?.find(i => i.student_id == data.id)?.attendance_type_id"
                          :key="index">{{ item.name }}
                      </option>
                    </select>
                  </div>

                </template>
              </Column>

              <Column header="Обновление">
                <template #body="{data}">
                  <div v-if="attendanceReport_form?.attendanceReportData?.find(i => i.student_id == data.id)">
                    <Button label="Обновить" class="p-button-primary p-mr-2"
                            @click="openAttendanceTypeDialog(data.id)"/>
                  </div>
                  <div v-else>
                    Еще не сохранено
                  </div>
                </template>
              </Column>






            </DataTable>
<!--            v-if="attendanceReport_form.attendanceReportData.length == 0"-->
            <div class="col-md-12 mt-4 text-center" style="min-height: 120px">

              <button type="button" class="btn btn-primary" @click="postAttendance">
                Сохранить
              </button>


            </div>
          </div>








        </div>


        <!-- Contract List Modal -->
        <Dialog header="Обновить тип посещения"
                v-model:visible="displayAttendanceTypeDialog"
                :modal="true"
                :style="{width: '50vw'}">

          <div class="row mt-4">
            <div class="col-md-3"><p><strong>Тип посещения</strong></p></div>
            <div class="col-md-9">
              <select class="form-control form-select"
                      @input="changeAttendanceType(attendanceReport_form?.attendanceReportData?.find(i => i.student_id == updateStudentId)?.id, $event)">
                <option
                    v-for="(item, index) in attendanceReport_form.attendanceType"
                    :value="item.id"
                    :selected="item.id == attendanceReport_form?.attendanceReportData?.find(i => i.student_id == updateStudentId)?.attendance_type_id"
                    :key="index">{{ item.name }}
                </option>
              </select>
            </div>
          </div>


          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeAttendanceTypeDialog"/>
            <Button label="Обновить" icon="pi pi-check" @click="putAttendance" />
          </template>
        </Dialog>
        <!-- Contract List Modal End-->

      </div>


    </div>






  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AttendanceReport",


  data() {
    return {
      date1: null,
      mdl_course_id: +this.$route.query.mdl_course_id || 1,
      education_courses_id: +this.$route.query.education_courses_id || 1,
      groupId: 0,
      displayAttendanceTypeDialog: false,
      updateStudentId: null
    }
  },
  computed: {
    ...mapState('attendanceReport', ['attendanceReport_form']),
    getTodayDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yyyy = today.getFullYear();
      today = dd + '.' + mm + '.' + yyyy;
      return today
    }

  },

  methods: {
    ...mapActions('attendanceReport', ['GET_EDUCATION_COURSE_DATA_BY_ID', 'GET_ATTENDANCE_TYPE',
      'POST_ATTENDANCE_REPORT','GET_ATTENDANCE_REPORT_DATA', 'PUT_ATTENDANCE_REPORT', 'GET_STUDENTS_BY_MDL_COURSE_ID']),
    ...mapMutations('attendanceReport', ['SET_ATTENDANCE_REPORT', 'UPDATE_ATTENDANCE_REPORT',
      'SET_MDL_COURSE_ID','UPDATE_ATTENDANCE_TYPE', 'SET_ATTENDANCE_REPORT_RATINGS', 'SET_ATTENDANCE_REPORT_DAY']),

    openAttendanceTypeDialog(student_id) {
      this.updateStudentId = student_id
      this.displayAttendanceTypeDialog = true
    },
    closeAttendanceTypeDialog(){
      this.displayAttendanceTypeDialog = false
    },


    changeGroup(e){

      this.groupId = e.target.value
      console.log(this.groupId, 'groupId')

      this.filteredStudents = this.attendanceReport_form.students.filter(i => i.group_id == this.groupId)


      console.log(this.filteredStudents, 'filteredStudents')
      this.SET_ATTENDANCE_REPORT(this.filteredStudents)
    },

    changeAttendance(student_id, e){
      let attendance_type_id = e.target.value
      this.UPDATE_ATTENDANCE_REPORT({student_id,attendance_type_id})
    },

    changeAttendanceType(students_attendance_id,e) {
      const attendance_type_id = e.target.value
      console.log(attendance_type_id, "attendance_type_id")
      console.log(students_attendance_id, "students_attendance_id")
      this.UPDATE_ATTENDANCE_TYPE({students_attendance_id, attendance_type_id})
    },
    async changeAttendanceReportDay(attendanceReportDay) {

      let localDay = new Date(attendanceReportDay);
      console.log(localDay, 'localDay')
      let day = parseInt((new Date(localDay).getTime() / 1000).toFixed(0))
      console.log(day, 'day')
      //await this.SET_ATTENDANCE_REPORT_DAY(day)
      await this.GET_ATTENDANCE_REPORT_DATA({mdl_course_id:this.mdl_course_id, day:day})
    },

    async postAttendance(){
      await this.POST_ATTENDANCE_REPORT()
      await this.GET_ATTENDANCE_REPORT_DATA({mdl_course_id: this.mdl_course_id})
    },
    async putAttendance(){
      await this.PUT_ATTENDANCE_REPORT()
      this.displayAttendanceTypeDialog = false
      await this.GET_ATTENDANCE_REPORT_DATA({mdl_course_id: this.mdl_course_id})
    }

  },
  async mounted() {

    await this.GET_EDUCATION_COURSE_DATA_BY_ID(this.education_courses_id)
    await this.GET_STUDENTS_BY_MDL_COURSE_ID(this.mdl_course_id)
    await this.GET_ATTENDANCE_TYPE()
    await this.SET_MDL_COURSE_ID(this.mdl_course_id)
    await this.GET_ATTENDANCE_REPORT_DATA({mdl_course_id: this.mdl_course_id})
  },
  created() {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevWeekMonday = new Date(today.setDate(today.getDate() - 6));
    let prevMonth = (month === 0) ? 11 : month -1;
    let prevYear = (prevMonth === 11) ? year - 1 : year;

    //this.minDate = new Date();

    //Get today's date using the JavaScript Date object.
    let minDate = new Date();

//Change it so that it is 7 days in the past.

    // set to Monday of this week
    let currentWeekMonday = today.setDate(today.getDate() - (today.getDay() + 6) % 7);
    console.log(currentWeekMonday, 'currentWeekMonday')
    let pastDate = minDate.getDate() - 7;
    minDate.setDate(pastDate);

//Log the date to our web console.
    console.log(minDate, 'minDate');
    this.minDate = minDate



    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    this.maxDate.setFullYear(year);

    console.log(this.maxDate , 'maxDate')

    let invalidDate = new Date();
    invalidDate.setDate(today.getDate() - 1);
    this.invalidDates = [today,invalidDate];
  },

}
</script>

<style scoped>

</style>